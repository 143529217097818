import { aggregateTypesListReducer } from 'ducks/reducers/aggregateTypesListReducer';
import { dataTypesListReducer } from 'ducks/reducers/dataTypesListReducer';
import { formatTypesListReducer } from 'ducks/reducers/formatTypesListReducer';
import { operatorsListReducer } from 'ducks/reducers/operatorsListReducer';
import { reportAuditsReducer } from 'ducks/reducers/reportAuditsReducer';
import { reportEntitiesListReducer } from 'ducks/reducers/reportEntitiesListReducer';
import { reportEntityFieldsReducer } from 'ducks/reducers/reportEntityFieldsReducer';
import { reportFolderReducer } from 'ducks/reducers/reportFolderReducer';
import { reportFoldersReducer } from 'ducks/reducers/reportFoldersReducer';
import { sortDirectionsListReducer } from 'ducks/reducers/sortDirectionsListReducer';
import { strategyWarningsReducer } from 'ducks/reducers/strategyWarningsReducer';
import { combineReducers } from 'redux';

import { abTestReducer } from './abTestReducer';
import { abTestSegmentReducer } from './abTestSegmentReducer';
import { abTestSegmentsReducer } from './abTestSegmentsReducer';
import { abTestsReducer } from './abTestsReducer';
import { adServerCampaignIdentifiersListReducer } from './adServerCampaignIdentifiersListReducer';
import { adServersListReducer } from './adServersListReducer';
import { advertiserModelsReducer } from './advertiserModelsReducer';
import { advertiserReducer } from './advertiserReducer';
import { advertiserReportReducer } from './advertiserReportReducer';
import { advertiserReportsReducer } from './advertiserReportsReducer';
import { advertisersReducer } from './advertisersReducer';
import { attributionTypesListReducer } from './attributionTypesListReducer';
import { bidAlgorithmsListReducer } from './bidAlgorithmsListReducer';
import { budgetTypesListReducer } from './budgetTypesListReducer';
import { campaignCumulativePerformanceReducer } from './campaignCumulativePerformanceReducer';
import { campaignDailyMarginReducer } from './campaignDailyMarginReducer';
import { campaignDailyPacingReducer } from './campaignDailyPacingReducer';
import { campaignDiscrepanciesReducer } from './campaignDiscrepanciesReducer';
import { campaignGroupsReducer } from './campaignGroupsReducer';
import { campaignIncrementalityReducer } from './campaignIncrementalityReducer';
import { campaignKpiPerformanceReducer } from './campaignKpiPerformanceReducer';
import { campaignMetricsReducer } from './campaignMetricsReducer';
import { campaignMovingAverageReducer } from './campaignMovingAverage';
import { campaignPacingPerformanceReducer } from './campaignPacingPerformanceReducer';
import { campaignReducer } from './campaignReducer';
import { campaignRepresentativesReducer } from './campaignRepresentativesReducer';
import { campaignRolesListReducer } from './campaignRolesListReducer';
import { campaignsReducer } from './campaignsReducer';
import { categoriesListReducer } from './categoriesListReducer';
import { changeOrderReducer } from './changeOrderReducer';
import { changeOrderStatesListReducer } from './changeOrderStatesListReducer';
import { changeOrderValidationReducer } from './changeOrderValidationReducer';
import { changeOrdersReducer } from './changeOrdersReducer';
import { citiesListReducer } from './citiesListReducer';
import { countriesListReducer } from './countriesListReducer';
import { creativeAddonsListReducer } from './creativeAddonsListReducer';
import { creativeAddonsReducer } from './creativeAddonsReducer';
import { creativeContentsReducer } from './creativeContentsReducer';
import { creativeExtrasListReducer } from './creativeExtrasListReducer';
import { creativeExtrasReducer } from './creativeExtrasReducer';
import { creativeMessagesListReducer } from './creativeMessagesListReducer';
import { creativePixelsReducer } from './creativePixelsReducer';
import { creativePricesReducer } from './creativePricesReducer';
import { creativeReducer } from './creativeReducer';
import { creativeScriptsReducer } from './creativeScriptsReducer';
import { creativeSelectionsListReducer } from './creativeSelectionsListReducer';
import { creativeTypesListReducer } from './creativeTypesListReducer';
import { creativeVideoEventsListReducer } from './creativeVideoEventsListReducer';
import { creativeVideoEventsReducer } from './creativeVideoEventsReducer';
import { creativesListReducer } from './creativesListReducer';
import { creativesReducer } from './creativesReducer';
import { dashboardReducer } from './dashboardReducer';
import { dashboardsReducer } from './dashboardsReducer';
import { datePreviousRangesListReducer } from './datePreviousRangesListReducer';
import { dateRangesListReducer } from './dateRangesListReducer';
import { dealReducer } from './dealReducer';
import { dealsReducer } from './dealsReducer';
import { demographicsListReducer } from './demographicsListReducer';
import { domainsListReducer } from './domainsListReducer';
import { dspAccountsListReducer } from './dspAccountsListReducer';
import { featureDefinitionsListReducer } from './featureDefinitionsListReducer';
import { filterReducer } from './filterReducer';
import { filterSourcesListReducer } from './filterSourcesListReducer';
import { filtersReducer } from './filtersReducer';
import { flightCreativesReducer } from './flightCreativesReducer';
import { flightReducer } from './flightReducer';
import { flightsReducer } from './flightsReducer';
import { frequencyCapIntervalsListReducer } from './frequencyCapIntervalsListReducer';
import { frequencyCapsReducer } from './frequencyCapsReducer';
import { frequencyIntervalsListReducer } from './frequencyIntervalsListReducer';
import { globalDealsReducer } from './globalDealsReducer';
import { globalVendorFeesReducer } from './globalVendorFeesReducer';
import { identityGraphsListReducer } from './identityGraphsListReducer';
import { identityTypesListReducer } from './identityTypesListReducer';
import { intervalsListReducer } from './intervalsListReducer';
import { inventorySourcesReducer } from './inventorySourcesReducer';
import { kpiGroupReducer } from './kpiGroupReducer';
import { kpiGroupsListReducer } from './kpiGroupsListReducer';
import { kpiGroupsReducer } from './kpiGroupsReducer';
import { kpiIntervalReducer } from './kpiIntervalReducer';
import { kpiIntervalsReducer } from './kpiIntervalsReducer';
import { lineItemCreativesReducer } from './lineItemCreativesReducer';
import { lineItemDcpmPreviewedReducer } from './lineItemDcpmPreviewedReducer';
import { lineItemDcpmReducer } from './lineItemDcpmReducer';
import { lineItemReducer } from './lineItemReducer';
import { lineItemTypesListReducer } from './lineItemTypesListReducer';
import { lineItemsExternalReducer } from './lineItemsExternalReducer';
import { lineItemsPacingReducer } from './lineItemsPacingReducer';
import { lineItemsReducer } from './lineItemsReducer';
import { listReducer } from './listReducer';
import { listsReducer } from './listsReducer';
import { logsReducer } from './logsReducer';
import { machineLearningJobsReducer } from './machineLearningJobsReducer';
import { measureTypesListReducer } from './measureTypesListReducer';
import { measureUnitsListReducer } from './measureUnitsListReducer';
import { metroAreasListReducer } from './metroAreasListReducer';
import { modalsReducer } from './modalsReducer';
import { modelAdvertisersReducer } from './modelAdvertisersReducer';
import { modelOutputsReducer } from './modelOutputsReducer';
import { modelReducer } from './modelReducer';
import { modelsReducer } from './modelsReducer';
import { operatingSystemsListReducer } from './operatingSystemsListReducer';
import { organizationsReducer } from './organizationsReducer';
import { outputTypesListReducer } from './outputTypesListReducer';
import { outputsReducer } from './outputsReducer';
import { overlayReducer } from './overlayReducer';
import { panelsReducer } from './panelsReducer';
import { parameterGroupsReducer } from './parameterGroupsReducer';
import { parameterTypesReducer } from './parameterTypesReducer';
import { parametersReducer } from './parametersReducer';
import { partnerReducer } from './partnerReducer';
import { partnersReducer } from './partnersReducer';
import { physicalTagReducer } from './physicalTagReducer';
import { physicalTagsReducer } from './physicalTagsReducer';
import { pixelTypesListReducer } from './pixelTypesListReducer';
import { platformsListReducer } from './platformsListReducer';
import { portfolioMarginReducer } from './portfolioMarginReducer';
import { portfolioOverviewReducer } from './portfolioOverviewReducer';
import { postalCodesListReducer } from './postalCodesListReducer';
import { queryParametersReducer } from './queryParametersReducer';
import { regionsListReducer } from './regionsListReducer';
import { reportConfigurationReducer } from './reportConfigurationReducer';
import { reportConfigurationsReducer } from './reportConfigurationsReducer';
import { reportParametersReducer } from './reportParametersReducer';
import { reportSchedulesReducer } from './reportSchedulesReducer';
import { reportTypeParameterGroupsReducer } from './reportTypeParameterGroupsReducer';
import { reportTypesReducer } from './reportTypesReducer';
import { reportsReducer } from './reportsReducer';
import { revenueTypesList } from './revenueTypesListReducer';
import { rolesReducer } from './rolesReducer';
import { salesforceFlightsReducer } from './salesforceFlightsReducer';
import { securityAuditsReducer } from './securityAuditsReducer';
import { segmentReducer } from './segmentReducer';
import { segmentsReducer } from './segmentsReducer';
import { settingsReducer } from './settingsReducer';
import { slackChannelsReducer } from './slackChannelsReducer';
import { statesListReducer } from './statesListReducer';
import { strategiesReducer } from './strategiesReducer';
import { strategyAbTestReducer } from './strategyAbTestReducer';
import { strategyAbTestsRedcuer } from './strategyAbTestsReducer';
import { strategyBudgetTypesListReducer } from './strategyBudgetTypesListReducer';
import { strategyReducer } from './strategyReducer';
import { strategyTemplateReducer } from './strategyTemplateReducer';
import { strategyTemplatesReducer } from './strategyTemplatesReducer';
import { tagFieldsReducer } from './tagFieldsReducer';
import { tagOperatorsListReducer } from './tagOperatorsListReducer';
import { tagReducer } from './tagReducer';
import { tagSummaryReducer } from './tagSummary';
import { tagTypesListReducer } from './tagTypesListReducer';
import { tagVariableRulesReducer } from './tagVariableRulesReducer';
import { tagsReducer } from './tagsReducer';
import { thirdPartyReportCadencesListReducer } from './thirdPartyReportCadencesListReducer';
import { thirdPartyReportDeliveriesListReducer } from './thirdPartyReportDeliveriesListReducer';
import { thirdPartyReportReducer } from './thirdPartyReportReducer';
import { thirdPartyReportSourcesListReducer } from './thirdPartyReportSourcesListReducer';
import { thirdPartyReportsReducer } from './thirdPartyReportsReducer';
import { titanKpiTypesReducer } from './titanKpiTypes';
import { userAdminReducer } from './userAdminReducer';
import { userReducer } from './userReducer';
import { usersAdminReducer } from './usersAdminReducer';
import { vendorFeesListReducer } from './vendorFeesListReducer';
import { vendorFeesReducer } from './vendorFeesReducer';
import { vendorsListReducer } from './vendorsListReducer';
import { videoBitratesListReducer } from './videoBitratesListReducer';
import { videoMimeTypesListReducer } from './videoMimeTypesListReducer';
import { videoPlacementsListReducer } from './videoPlacementsListReducer';
import { virtualTagReducer } from './virtualTagReducer';
import { virtualTagsReducer } from './virtualTagsReducer';

const rootReducer = combineReducers({
  ab_test: abTestReducer,
  ab_tests: abTestsReducer,
  ab_test_segment: abTestSegmentReducer,
  ab_test_segments: abTestSegmentsReducer,
  ad_servers_list: adServersListReducer,
  ad_server_campaign_identifiers_list: adServerCampaignIdentifiersListReducer,
  organizations: organizationsReducer,
  advertiser: advertiserReducer,
  advertisers: advertisersReducer,
  attribution_types_list: attributionTypesListReducer,
  bid_algorithms_list: bidAlgorithmsListReducer,
  budget_types_list: budgetTypesListReducer,
  campaign: campaignReducer,
  campaigns: campaignsReducer,
  campaign_representatives: campaignRepresentativesReducer,
  campaign_cumulative_performance: campaignCumulativePerformanceReducer,
  campaign_incrementality: campaignIncrementalityReducer,
  campaign_daily_pacing: campaignDailyPacingReducer,
  campaign_daily_margin: campaignDailyMarginReducer,
  campaign_discrepancies: campaignDiscrepanciesReducer,
  campaign_groups: campaignGroupsReducer,
  campaign_kpi_performance: campaignKpiPerformanceReducer,
  campaign_metrics: campaignMetricsReducer,
  campaign_moving_average: campaignMovingAverageReducer,
  campaign_pacing_performance: campaignPacingPerformanceReducer,
  campaign_roles_list: campaignRolesListReducer,
  categories_list: categoriesListReducer,
  change_order: changeOrderReducer,
  change_order_validation: changeOrderValidationReducer,
  change_order_states_list: changeOrderStatesListReducer,
  change_orders: changeOrdersReducer,
  cities_list: citiesListReducer,
  creative: creativeReducer,
  creative_extras: creativeExtrasReducer,
  creative_add_ons: creativeAddonsReducer,
  creative_contents: creativeContentsReducer,
  creative_pixels: creativePixelsReducer,
  creative_scripts: creativeScriptsReducer,
  creative_add_ons_list: creativeAddonsListReducer,
  creative_selections_list: creativeSelectionsListReducer,
  creative_prices: creativePricesReducer,
  creative_types_list: creativeTypesListReducer,
  creative_messages_list: creativeMessagesListReducer,
  creative_video_events: creativeVideoEventsReducer,
  creative_video_events_list: creativeVideoEventsListReducer,
  creative_extras_list: creativeExtrasListReducer,
  creatives: creativesReducer,
  creatives_list: creativesListReducer,
  countries_list: countriesListReducer,
  date_ranges_list: dateRangesListReducer,
  date_previous_ranges_list: datePreviousRangesListReducer,
  deal: dealReducer,
  deals: dealsReducer,
  demographics_list: demographicsListReducer,
  domains_list: domainsListReducer,
  dsp_accounts_list: dspAccountsListReducer,
  feature_definitions_list: featureDefinitionsListReducer,
  filter: filterReducer,
  filter_sources_list: filterSourcesListReducer,
  filters: filtersReducer,
  flight: flightReducer,
  flight_creatives: flightCreativesReducer,
  flights: flightsReducer,
  frequency_cap_intervals_list: frequencyCapIntervalsListReducer,
  frequency_caps: frequencyCapsReducer,
  frequency_intervals_list: frequencyIntervalsListReducer,
  global_deals: globalDealsReducer,
  global_vendor_fees: globalVendorFeesReducer,
  intervals_list: intervalsListReducer,
  inventory_sources_list: inventorySourcesReducer,
  kpi_group: kpiGroupReducer,
  kpi_interval: kpiIntervalReducer,
  kpi_intervals: kpiIntervalsReducer,
  kpi_groups: kpiGroupsReducer,
  kpi_groups_list: kpiGroupsListReducer,
  line_item: lineItemReducer,
  line_item_creatives: lineItemCreativesReducer,
  line_item_dcpm: lineItemDcpmReducer,
  line_item_dcpm_previewed: lineItemDcpmPreviewedReducer,
  line_items: lineItemsReducer,
  line_items_external: lineItemsExternalReducer,
  line_items_pacing: lineItemsPacingReducer,
  line_item_types_list: lineItemTypesListReducer,
  list: listReducer,
  lists: listsReducer,
  logs: logsReducer,
  machine_learning_jobs: machineLearningJobsReducer,
  measure_types_list: measureTypesListReducer,
  measure_units_list: measureUnitsListReducer,
  metro_areas_list: metroAreasListReducer,
  modals: modalsReducer,
  operating_systems_list: operatingSystemsListReducer,
  panels: panelsReducer,
  parameter_groups: parameterGroupsReducer,
  parameter_types: parameterTypesReducer,
  parameters: parametersReducer,
  partner: partnerReducer,
  partners: partnersReducer,
  platforms_list: platformsListReducer,
  portfolio_margin: portfolioMarginReducer,
  portfolio_overview: portfolioOverviewReducer,
  regions_list: regionsListReducer,
  advertiser_report: advertiserReportReducer,
  advertiser_reports: advertiserReportsReducer,
  reports: reportsReducer,
  report_parameters: reportParametersReducer,
  report_types: reportTypesReducer,
  report_type_parameter_groups: reportTypeParameterGroupsReducer,
  revenue_types_list: revenueTypesList,
  physical_tag: physicalTagReducer,
  physical_tags: physicalTagsReducer,
  pixel_types_list: pixelTypesListReducer,
  postal_codes_list: postalCodesListReducer,
  salesforce_flights: salesforceFlightsReducer,
  security_audits: securityAuditsReducer,
  segment: segmentReducer,
  segments: segmentsReducer,
  settings: settingsReducer,
  states_list: statesListReducer,
  strategy: strategyReducer,
  strategy_ab_test: strategyAbTestReducer,
  strategy_ab_tests: strategyAbTestsRedcuer,
  strategy_budget_types_list: strategyBudgetTypesListReducer,
  strategy_template: strategyTemplateReducer,
  strategy_templates: strategyTemplatesReducer,
  strategy_warnings: strategyWarningsReducer,
  strategies: strategiesReducer,
  tag: tagReducer,
  tag_operators_list: tagOperatorsListReducer,
  tag_summary: tagSummaryReducer,
  tag_types_list: tagTypesListReducer,
  tag_fields: tagFieldsReducer,
  tags: tagsReducer,
  tag_variable_rules: tagVariableRulesReducer,
  third_party_report: thirdPartyReportReducer,
  third_party_report_cadences_list: thirdPartyReportCadencesListReducer,
  third_party_report_deliveries_list: thirdPartyReportDeliveriesListReducer,
  third_party_report_sources_list: thirdPartyReportSourcesListReducer,
  third_party_reports: thirdPartyReportsReducer,
  user: userReducer,
  users_admin: usersAdminReducer,
  user_admin: userAdminReducer,
  vendor_fees: vendorFeesReducer,
  vendor_fees_list: vendorFeesListReducer,
  vendors_list: vendorsListReducer,
  video_bitrates_list: videoBitratesListReducer,
  video_mime_types_list: videoMimeTypesListReducer,
  video_placements_list: videoPlacementsListReducer,
  virtual_tag: virtualTagReducer,
  virtual_tags: virtualTagsReducer,
  roles: rolesReducer,
  overlay: overlayReducer,
  query_parameters: queryParametersReducer,
  models: modelsReducer,
  model: modelReducer,
  model_advertisers: modelAdvertisersReducer,
  model_outputs: modelOutputsReducer,
  outputs: outputsReducer,
  advertiser_models: advertiserModelsReducer,
  report_entity_fields: reportEntityFieldsReducer,
  report_entities_list: reportEntitiesListReducer,
  dashboard: dashboardReducer,
  dashboards: dashboardsReducer,
  report_folders: reportFoldersReducer,
  report_folder: reportFolderReducer,
  report_configurations: reportConfigurationsReducer,
  report_configuration: reportConfigurationReducer,
  report_audits: reportAuditsReducer,
  operators_list: operatorsListReducer,
  aggregate_types_list: aggregateTypesListReducer,
  data_types_list: dataTypesListReducer,
  format_types_list: formatTypesListReducer,
  sort_directions_list: sortDirectionsListReducer,
  slack_channels: slackChannelsReducer,
  report_schedules: reportSchedulesReducer,
  output_types_list: outputTypesListReducer,
  identity_graphs_list: identityGraphsListReducer,
  identity_types_list: identityTypesListReducer,
  titan_kpi_types: titanKpiTypesReducer,
});

export default rootReducer;
