import { CLEAR_SALESFORCE_FLIGHTS, SET_SALESFORCE_FLIGHTS } from '../types';

export const salesforceFlightsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_SALESFORCE_FLIGHTS:
      return action.payload;
    case CLEAR_SALESFORCE_FLIGHTS:
      return [];
    default:
      return state;
  }
};
