import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeSalesforceFlights = (salesforce_flights = []) =>
  salesforce_flights
    .map((s, i) => normalizeSalesforceFlight(s, i))
    .sort((a, b) => checkSort(a, b, 'campaign_name', 'asc'));

export const normalizeSalesforceFlight = (row = {}, index) => {
  return {
    ...row,
    uuid: uuidv4(),
  };
};
