// AB TEST ACTIONS
export const SET_AB_TEST = 'SET_AB_TEST';
export const CLEAR_AB_TEST = 'CLEAR_AB_TEST';

// AB TESTS ACTIONS
export const SET_AB_TESTS = 'SET_AB_TESTS';
export const CLEAR_AB_TESTS = 'CLEAR_AB_TESTS';

// AB TEST SEGMENT ACTIONS
export const SET_AB_TEST_SEGMENT = 'SET_AB_TEST_SEGMENT';
export const CLEAR_AB_TEST_SEGMENT = 'CLEAR_AB_TEST_SEGMENT';

// TITAN KPI TYPES ACTIONS
export const SET_TITAN_KPI_TYPES = 'SET_TITAN_KPI_TYPES';
export const CLEAR_TITAN_KPI_TYPES = 'CLEAR_TITAN_KPI_TYPES';

// AB TEST SEGMENTS ACTIONS
export const SET_AB_TEST_SEGMENTS = 'SET_AB_TEST_SEGMENTS';
export const UPDATE_AB_TEST_SEGMENTS = 'UPDATE_AB_TEST_SEGMENTS';
export const CLEAR_AB_TEST_SEGMENTS = 'CLEAR_AB_TEST_SEGMENTS';

// AD SERVERS LIST ACTIONS
export const SET_AD_SERVERS_LIST = 'SET_AD_SERVERS_LIST';
export const CLEAR_AD_SERVERS_LIST = 'CLEAR_AD_SERVERS_LIST';

// AD SERVER CAMPAIGN IDENTIFIERS LIST ACTIONS
export const SET_AD_SERVER_CAMPAIGN_IDENTIFIERS_LIST = 'SET_AD_SERVER_CAMPAIGN_IDENTIFIERS_LIST';
export const CLEAR_AD_SERVER_CAMPAIGN_IDENTIFIERS_LIST = 'CLEAR_AD_SERVER_CAMPAIGN_IDENTIFIERS_LIST';

// ADMIN REDUCER ACTIONS
export const SET_ADMIN_USERS = 'SET_ADMIN_USERS';
export const CLEAR_ADMIN_USERS = 'CLEAR_ADMIN_USERS';
export const SET_USER_ADMIN = 'SET_USER_ADMIN';
export const CLEAR_USER_ADMIN = 'CLEAR_USER_ADMIN';
export const SET_USERS_ADMIN = 'SET_USERS_ADMIN';
export const CLEAR_USERS_ADMIN = 'CLEAR_USERS_ADMIN';

// ADVERTISER REDUCER ACTIONS
export const SET_ADVERTISER = 'SET_ADVERTISER';
export const CLEAR_ADVERTISER = 'CLEAR_ADVERTISER';

// ADVERTISERS REDUCER ACTIONS
export const SET_ADVERTISERS = 'SET_ADVERTISERS';
export const CLEAR_ADVERTISERS = 'CLEAR_ADVERTISERS';

// ATTRIBUTION TYPES REDUCER ACTIONS
export const SET_ATTRIBUTION_TYPES_LIST = 'SET_ATTRIBUTION_TYPES_LIST';
export const CLEAR_ATTRIBUTION_TYPES_LIST = 'CLEAR_ATTRIBUTION_TYPES_LIST';

// BID ALGORITHMS LIST REDUCER ACTIONS
export const SET_BID_ALGORITHMS_LIST = 'SET_BID_ALGORITHMS_LIST';
export const CLEAR_BID_ALGORITHMS_LIST = 'CLEAR_BID_ALGORITHMS_LIST';

// BUDGET TYPES LIST REDUCER ACTIONS
export const SET_BUDGET_TYPES_LIST = 'SET_BUDGET_TYPES_LIST';
export const CLEAR_BUDGET_TYPES_LIST = 'CLEAR_BUDGET_TYPES_LIST';

// BIG ALGORITHMS REDUCER ACTIONS
export const SET_BID_ALGORITHMS = 'SET_BID_ALGORITHMS';
export const CLEAR_BID_ALGORITHMS = 'CLEAR_BID_ALGORITHMS';

// CAMPAIGN REDUCER ACTIONS
export const SET_CAMPAIGN = 'SET_CAMPAIGN';
export const CLEAR_CAMPAIGN = 'CLEAR_CAMPAIGN';

// CAMPAIGNS REDUCER ACTIONS
export const SET_CAMPAIGNS = 'SET_CAMPAIGNS';
export const CLEAR_CAMPAIGNS = 'CLEAR_CAMPAIGNS';

// CAMPAIGN REPRESENTATIVES REDUCER ACTIONS
export const SET_CAMPAIGN_REPRESENTATIVES = 'SET_CAMPAIGN_REPRESENTATIVES';
export const CLEAR_CAMPAIGN_REPRESENTATIVES = 'CLEAR_CAMPAIGN_REPRESENTATIVES';

// CAMPAIGN ROLES LIST ACTIONS
export const SET_CAMPAIGN_ROLES_LIST = 'SET_CAMPAIGN_ROLES_LIST';
export const CLEAR_CAMPAIGN_ROLES_LIST = 'CLEAR_CAMPAIGN_ROLES_LIST';

// CAMPAIGN DAILY MARGIN REDUCER ACTIONS
export const SET_CAMPAIGN_DAILY_MARGIN = 'SET_CAMPAIGN_DAILY_MARGIN';
export const CLEAR_CAMPAIGN_DAILY_MARGIN = 'CLEAR_CAMPAIGN_DAILY_MARGIN';

// CAMPAIGN DAILY PACING REDUCER ACTIONS
export const SET_CAMPAIGN_DAILY_PACING = 'SET_CAMPAIGN_DAILY_PACING';
export const CLEAR_CAMPAIGN_DAILY_PACING = 'CLEAR_CAMPAIGN_DAILY_PACING';

// CAMPAIGN DISCREPANCIES REDUCER ACTIONS
export const SET_CAMPAIGN_DISCREPANCIES = 'SET_CAMPAIGN_DISCREPANCIES';
export const UPDATE_CAMPAIGN_DISCREPANCIES = 'UPDATE_CAMPAIGN_DISCREPANCIES';
export const CLEAR_CAMPAIGN_DISCREPANCIES = 'CLEAR_CAMPAIGN_DISCREPANCIES';

// CAMPAIGN CUMULATIVE PERFORMANCE REDUCER ACTIONS
export const SET_CAMPAIGN_CUMULATIVE_PERFORMANCE = 'SET_CAMPAIGN_CUMULATIVE_PERFORMANCE';
export const CLEAR_CAMPAIGN_CUMULATIVE_PERFORMANCE = 'CLEAR_CAMPAIGN_CUMULATIVE_PERFORMANCE';

// CAMPAIGN MOVING AVERAGE REDUCER ACTIONS
export const SET_CAMPAIGN_MOVING_AVERAGE = 'SET_CAMPAIGN_MOVING_AVERAGE';
export const SET_CAMPAIGN_CUMULATIVE_PACING_MOVING_AVERAGE = 'SET_CAMPAIGN_CUMULATIVE_PACING_MOVING_AVERAGE';
export const SET_CAMPAIGN_RETARGETING_MOVING_AVERAGE = 'SET_CAMPAIGN_RETARGETING_MOVING_AVERAGE';
export const SET_CAMPAIGN_PROSPECTING_MOVING_AVERAGE = 'SET_CAMPAIGN_PROSPECTING_MOVING_AVERAGE';
export const SET_CAMPAIGN_PROSPECTING_RETARGETING_MOVING_AVERAGE =
  'SET_CAMPAIGN_PROSPECTING_RETARGETING_MOVING_AVERAGE';
export const SET_CAMPAIGN_PROSPECTING_SHARES_MOVING_AVERAGE = 'SET_CAMPAIGN_PROSPECTING_SHARES_MOVING_AVERAGE';
export const CLEAR_CAMPAIGN_MOVING_AVERAGE = 'CLEAR_CAMPAIGN_MOVING_AVERAGE';

// CAMPAIGN METRICS REDUCER ACTIONS
export const SET_CAMPAIGN_METRICS = 'SET_CAMPAIGN_METRICS';
export const CLEAR_CAMPAIGN_METRICS = 'CLEAR_CAMPAIGN_METRICS';

// CAMPAIGN INCREMENTALITY REDUCER ACTIONS
export const SET_CAMPAIGN_INCREMENTALITY_OVERVIEW = 'SET_CAMPAIGN_INCREMENTALITY_OVERVIEW';
export const SET_CAMPAIGN_INCREMENTALITY_EXPERIMENT = 'SET_CAMPAIGN_INCREMENTALITY_EXPERIMENT';
export const SET_CAMPAIGN_INCREMENTALITY_CONTROL = 'SET_CAMPAIGN_INCREMENTALITY_CONTROL';
export const CLEAR_CAMPAIGN_INCREMENTALITY = 'CLEAR_CAMPAIGN_INCREMENTALITY';

// CAMPAIGN PACING PERFORMANCE REDUCER ACTIONS
export const SET_CAMPAIGN_PACING_PERFORMANCE = 'SET_CAMPAIGN_PACING_PERFORMANCE';
export const CLEAR_CAMPAIGN_PACING_PERFORMANCE = 'CLEAR_CAMPAIGN_PACING_PERFORMANCE';

// CAMPAIGN KPI PERFORMANCE REDUCER ACTIONS
export const SET_CAMPAIGN_KPI_PERFORMANCE = 'SET_CAMPAIGN_KPI_PERFORMANCE';
export const CLEAR_CAMPAIGN_KPI_PERFORMANCE = 'CLEAR_CAMPAIGN_KPI_PERFORMANCE';

// CAMPAIGN GROUPS REDUCER ACTIONS
export const SET_CAMPAIGN_GROUPS = 'SET_CAMPAIGN_GROUPS';
export const CLEAR_CAMPAIGN_GROUPS = 'CLEAR_CAMPAIGN_GROUPS';

// CATEGORIES LIST REDUCER ACTIONS
export const SET_CATEGORIES_LIST = 'SET_CATEGORIES_LIST';
export const CLEAR_CATEGORIES_LIST = 'CLEAR_CATEGORIES_LIST';

// CHANGE ORDER REDUCER ACTIONS
export const SET_CHANGE_ORDER = 'SET_CHANGE_ORDER';
export const UPDATE_CHANGE_ORDER = 'UPDATE_CHANGE_ORDER';
export const CLEAR_CHANGE_ORDER = 'CLEAR_CHANGE_ORDER';

// CHANGE ORDER STATES LIST REDUCER ACTIONS
export const SET_CHANGE_ORDER_STATES_LIST = 'SET_CHANGE_ORDER_STATES_LIST';
export const CLEAR_CHANGE_ORDER_STATES_LIST = 'CLEAR_CHANGE_ORDER_STATES_LIST';

// CHANGE ORDERS REDUCER ACTIONS
export const SET_CHANGE_ORDERS = 'SET_CHANGE_ORDERS';
export const CLEAR_CHANGE_ORDERS = 'CLEAR_CHANGE_ORDERS';

// CHANGE ORDER VALIDATION REDUCER ACTIONS
export const SET_CHANGE_ORDER_VALIDATION = 'SET_CHANGE_ORDER_VALIDATION';
export const CLEAR_CHANGE_ORDER_VALIDATION = 'CLEAR_CHANGE_ORDER_VALIDATION';

// CHANGE PASSWORD MODAL REDUCER ACTIONS
export const SET_CHANGE_PASSWORD_MODAL_DATA = 'SET_CHANGE_PASSWORD_MODAL_DATA';
export const CLEAR_CHANGE_PASSWORD_MODAL_DATA = 'CLEAR_CHANGE_PASSWORD_MODAL_DATA';

// UPDATE USER INFORMATION MODAL REDUCER ACTION
export const SET_UPDATE_USER_MODAL_DATA = 'SET_UPDATE_USER_MODAL_DATA';
export const CLEAR_UPDATE_USER_MODAL_DATA = 'CLEAR_UPDATE_USER_MODAL_DATA';

// ORGANIZATIONS REDUCER ACTIONS
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';
export const CLEAR_ORGANIZATIONS = 'CLEAR_ORGANIZATIONS';

// CITIES LIST REDUCER ACTIONS
export const SET_CITIES_LIST = 'SET_CITIES_LIST';
export const CLEAR_CITIES_LIST = 'CLEAR_CITIES_LIST';

// COUNTRIES LIST REDUCER ACTIONS
export const SET_COUNTRIES_LIST = 'SET_COUNTRIES_LIST';
export const CLEAR_COUNTRIES_LIST = 'CLEAR_COUNTRIES_LIST';

// CREATIVE REDUCER ACTIONS
export const SET_CREATIVE = 'SET_CREATIVE';
export const CLEAR_CREATIVE = 'CLEAR_CREATIVE';

// CREATIVE PRICES REDUCER ACTIONS
export const SET_CREATIVE_PRICES = 'SET_CREATIVE_PRICES';
export const CLEAR_CREATIVE_PRICES = 'CLEAR_CREATIVE_PRICES';

// CREATIVE ADDONS REDUCER ACTION
export const SET_CREATIVE_ADDONS = 'SET_CREATIVE_ADDONS';
export const CLEAR_CREATIVE_ADDONS = 'CLEAR_CREATIVE_ADDONS';

// CREATIVE CONTENTS REDUCER ACTION
export const SET_CREATIVE_CONTENTS = 'SET_CREATIVE_CONTENTS';
export const CLEAR_CREATIVE_CONTENTS = 'CLEAR_CREATIVE_CONTENTS';

// CREATIVE SCRIPTS REDUCER ACTION
export const SET_CREATIVE_SCRIPTS = 'SET_CREATIVE_SCRIPTS';
export const CLEAR_CREATIVE_SCRIPTS = 'CLEAR_CREATIVE_SCRIPTS';

// CREATIVE PIXELS REDUCER ACTION
export const SET_CREATIVE_PIXELS = 'SET_CREATIVE_PIXELS';
export const CLEAR_CREATIVE_PIXELS = 'CLEAR_CREATIVE_PIXELS';

// CREATIVE ADDONS LIST REDUCER ACTION
export const SET_CREATIVE_ADDONS_LIST = 'SET_CREATIVE_ADDONS_LIST';
export const CLEAR_CREATIVE_ADDONS_LIST = 'CLEAR_CREATIVE_ADDONS_LIST';

// CREATIVE EXTRAS REDUCER ACTION
export const SET_CREATIVE_EXTRAS = 'SET_CREATIVE_EXTRAS';
export const CLEAR_CREATIVE_EXTRAS = 'CLEAR_CREATIVE_EXTRAS';

// CREATIVE EXTRAS LIST REDUCER ACTIONS
export const SET_CREATIVE_EXTRAS_LIST = 'SET_CREATIVE_EXTRAS_LIST';
export const CLEAR_CREATIVE_EXTRAS_LIST = 'CLEAR_CREATIVE_EXTRAS_LIST';

// CREATIVE SELECTIONS LIST REDUCER ACTIONS
export const SET_CREATIVE_SELECTIONS_LIST = 'SET_CREATIVE_SELECTIONS_LIST';
export const CLEAR_CREATIVE_SELECTIONS_LIST = 'CLEAR_CREATIVE_SELECTIONS_LIST';

// CREATIVE TYPES LIST REDUCER ACTION
export const SET_CREATIVE_TYPES_LIST = 'SET_CREATIVE_TYPES_LIST';
export const CLEAR_CREATIVE_TYPES_LIST = 'CLEAR_CREATIVE_TYPES_LIST';

// CREATIVE VIDEO EVENTS REDUCER ACTION
export const SET_CREATIVE_VIDEO_EVENTS = 'SET_CREATIVE_VIDEO_EVENTS';
export const CLEAR_CREATIVE_VIDEO_EVENTS = 'CLEAR_CREATIVE_VIDEO_EVENTS';

// CREATIVE VIDEO EVENTS LIST REDUCER ACTION
export const SET_CREATIVE_VIDEO_EVENTS_LIST = 'SET_CREATIVE_VIDEO_EVENTS_LIST';
export const CLEAR_CREATIVE_VIDEO_EVENTS_LIST = 'CLEAR_CREATIVE_VIDEO_EVENTS_LIST';

// CREATIVES REDUCER ACTIONS
export const SET_CREATIVES = 'SET_CREATIVES';
export const CLEAR_CREATIVES = 'CLEAR_CREATIVES';

// CREATIVES LIST REDUCER ACTIONS
export const SET_CREATIVES_LIST = 'SET_CREATIVES_LIST';
export const CLEAR_CREATIVES_LIST = 'CLEAR_CREATIVES_LIST';

// DATE RANGES REDUCER ACTIONS
export const SET_DATE_RANGES = 'SET_DATE_RANGES';
export const CLEAR_DATE_RANGES = 'CLEAR_DATE_RANGES';

// DATE PREVIOUS RANGES REDUCER ACTIONS
export const SET_DATE_PREVIOUS_RANGES = 'SET_DATE_PREVIOUS_RANGES';
export const CLEAR_DATE_PREVIOUS_RANGES = 'CLEAR_DATE_PREVIOUS_RANGES';

// DEAL ACTIONS
export const SET_DEAL = 'SET_DEAL';
export const CLEAR_DEAL = 'CLEAR_DEAL';

// DEALS ACTIONS
export const SET_DEALS = 'SET_DEALS';
export const CLEAR_DEALS = 'CLEAR_DEALS';

// DEMOGRAPHICS LIST REDUCER ACTIONS
export const SET_DEMOGRAPHICS_LIST = 'SET_DEMOGRAPHICS_LIST';
export const CLEAR_DEMOGRAPHICS_LIST = 'CLEAR_DEMOGRAPHICS_LIST';

// DOMAIN REDUCER ACTIONS
export const SET_DOMAIN = 'SET_DOMAIN';
export const CLEAR_DOMAIN = 'CLEAR_DOMAIN';

// DOMAINS REDUCER ACTIONS
export const SET_DOMAINS_LIST = 'SET_DOMAINS_LIST';
export const CLEAR_DOMAINS_LIST = 'CLEAR_DOMAINS_LIST';

// DSP ACCOUNTS LIST REDUCER ACTIONS
export const SET_DSP_ACCOUNTS_LIST = 'SET_DSP_ACCOUNTS_LIST';
export const CLEAR_DSP_ACCOUNTS_LIST = 'CLEAR_DSP_ACCOUNTS_LIST';

// FEATURE DEFINITIONS LIST REDUCER ACTIONS
export const SET_FEATURE_DEFINITIONS_LIST = 'SET_FEATURE_DEFINITIONS_LIST';
export const CLEAR_FEATURE_DEFINITIONS_LIST = 'CLEAR_FEATURE_DEFINITIONS_LIST';

// FILTER REDUCER ACTIONS
export const SET_FILTER = 'SET_FILTER';
export const CLEAR_FILTER = 'CLEAR_FILTER';

// FILTER SOURCES LIST REDUCER ACTIONS
export const SET_FILTER_SOURCES_LIST = 'SET_FILTER_SOURCES_LIST';
export const CLEAR_FILTER_SOURCES_LIST = 'CLEAR_FILTER';

// FILTERS REDUCER ACTIONS
export const SET_FILTERS = 'SET_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

// FOLDERS REDUCER ACTIONS
export const SET_FOLDERS = 'SET_FOLDERS';
export const CLEAR_FOLDERS = 'CLEAR_FOLDERS';
export const SET_FOLDER = 'SET_FOLDER';
export const CLEAR_FOLDER = 'CLEAR_FOLDER';

// FLIGHT REDUCER ACTIONS
export const CLEAR_FLIGHT = 'CLEAR_FLIGHT';
export const SET_FLIGHT = 'SET_FLIGHT';

// FLIGHT CREATIVES REDUCER ACTIONS
export const SET_FLIGHT_CREATIVES = 'SET_FLIGHT_CREATIVES';
export const CLEAR_FLIGHT_CREATIVES = 'CLEAR_FLIGHT_CREATIVES';

// FLIGHTS REDUCER ACTIONS
export const CLEAR_FLIGHTS = 'CLEAR_FLIGHTS';
export const SET_FLIGHTS = 'SET_FLIGHTS';
export const UPDATE_FLIGHTS = 'UPDATE_FLIGHTS';

// FREQUENCY CAP INTERVALS LIST REDUCER ACTIONS
export const SET_FREQUENCY_CAP_INTERVALS_LIST = 'SET_FREQUENCY_CAP_INTERVALS_LIST';
export const CLEAR_FREQUENCY_CAP_INTERVALS_LIST = 'CLEAR_FREQUENCY_CAP_INTERVALS_LIST';

// FREQUENCY CAPS REDUCER ACTIONS
export const SET_FREQUENCY_CAPS = 'SET_FREQUENCY_CAPS';
export const CLEAR_FREQUENCY_CAPS = 'CLEAR_FREQUENCY_CAPS';

// FREQUENCY INTERVALS LIST REDUCER ACTIONS
export const SET_FREQUENCY_INTERVALS_LIST = 'SET_FREQUENCY_INTERVALS_LIST';
export const CLEAR_FREQUENCY_INTERVALS_LIST = 'CLEAR_FREQUENCY_INTERVALS_LIST';

// GLOBAL DEALS ACTIONS
export const SET_GLOBAL_DEALS = 'SET_GLOBAL_DEALS';
export const CLEAR_GLOBAL_DEALS = 'CLEAR_GLOBAL_DEALS';

// GLOBAL VENDOR FEES ACTIONS
export const SET_GLOBAL_VENDOR_FEES = 'SET_GLOBAL_VENDOR_FEES';
export const CLEAR_GLOBAL_VENDOR_FEES = 'CLEAR_GLOBAL_VENDOR_FEES';

// INTERVALS LIST REDUCER ACTIONS
export const SET_INTERVALS_LIST = 'SET_INTERVALS_LIST';
export const CLEAR_INTERVALS_LIST = 'CLEAR_INTERVALS_LIST';

// INVENTORY SOURCES LIST REDUCER ACTIONS
export const SET_INVENTORY_SOURCES_LIST = 'SET_INVENTORY_SOURCES_LIST';
export const CLEAR_INVENTORY_SOURCES_LIST = 'CLEAR_INVENTORY_SOURCES_LIST';

// KPI GROUP REDUCER ACTIONS
export const CLEAR_KPI_GROUP = 'CLEAR_KPI_GROUP';
export const SET_KPI_GROUP = 'SET_KPI_GROUP';

// KPI GROUP INTERVAL REUDCER ACTIONS
export const SET_KPI_INTERVAL = 'SET_KPI_INTERVAL';
export const CLEAR_KPI_INTERVAL = 'CLEAR_KPI_INTERVAL';

// KPI GROUP INTERVALS REDUCER ACTIONS
export const SET_KPI_INTERVALS = 'SET_KPI_INTERVALS';
export const CLEAR_KPI_INTERVALS = 'CLEAR_KPI_INTERVALS';

// KPI GROUPS REDUCER ACTIONS
export const CLEAR_KPI_GROUPS = 'CLEAR_KPI_GROUPS';
export const SET_KPI_GROUPS = 'SET_KPI_GROUPS';

// KPI GROUPS LIST REDUCER ACTIONS
export const CLEAR_KPI_GROUPS_LIST = 'CLEAR_KPI_GROUPS_LIST';
export const SET_KPI_GROUPS_LIST = 'SET_KPI_GROUPS_LIST';

// LINE ITEM REDUCER ACTIONS
export const SET_LINE_ITEM = 'SET_LINE_ITEM';
export const UPDATE_LINE_ITEM = 'UPDATE_LINE_ITEM';
export const CLEAR_LINE_ITEM = 'CLEAR_LINE_ITEM';

// LINE ITEM DCPM REDUCER ACTIONS
export const SET_LINE_ITEM_DCPM = 'SET_LINE_ITEM_DCPM';
export const CLEAR_LINE_ITEM_DCPM = 'CLEAR_LINE_ITEM_DCPM';

// LINE ITEM DCPM PREVIEWED REDUCER ACTIONS
export const SET_LINE_ITEM_DCPM_PREVIEWED = 'SET_LINE_ITEM_DCPM_PREVIEWED';
export const CLEAR_LINE_ITEM_DCPM_PREVIEWED = 'CLEAR_LINE_ITEM_DCPM_PREVIEWED';

// LINE ITEMS REDUCER ACTIONS
export const SET_LINE_ITEMS = 'SET_LINE_ITEMS';
export const CLEAR_LINE_ITEMS = 'CLEAR_LINE_ITEMS';

// LINE ITEM CREATIVES REDUCER ACTIONS
export const SET_LINE_ITEM_CREATIVES = 'SET_LINE_ITEM_CREATIVES';
export const CLEAR_LINE_ITEM_CREATIVES = 'CLEAR_LINE_ITEM_CREATIVES';

// LINE ITEMS EXTERNAL REDUCER ACTIONS
export const SET_LINE_ITEMS_EXTERNAL = 'SET_LINE_ITEMS_EXTERNAL';
export const CLEAR_LINE_ITEMS_EXTERNAL = 'CLEAR_LINE_ITEMS_EXTERNAL';

// LINE ITEMS PACING REDUCER ACTIONS
export const SET_LINE_ITEMS_PACING = 'SET_LINE_ITEMS_PACING';
export const CLEAR_LINE_ITEMS_PACING = 'CLEAR_LINE_ITEMS_PACING';

// LINE ITEM TYPES REDUCER ACTIONS
export const SET_LINE_ITEM_TYPES_LIST = 'SET_LINE_ITEM_TYPES_LIST';
export const CLEAR_LINE_ITEM_TYPES_LIST = 'CLEAR_LINE_ITEM_TYPES_LIST';

// LISTS REDUCER ACTIONS
export const SET_LISTS = 'SET_LISTS';
export const CLEAR_LISTS = 'CLEAR_LISTS';

// LIST REDUCER ACTIONS
export const SET_LIST = 'SET_LIST';
export const CLEAR_LIST = 'CLEAR_LIST';

// LOGS REDUCER ACTIONS
export const SET_LOGS = 'SET_LOGS';
export const CLEAR_LOGS = 'CLEAR_LOGS';

// MACHINE LEARNING REDUCER ACTIONS
export const SET_MACHINE_LEARNING_JOBS = 'SET_MACHINE_LEARNING_JOBS';
export const CLEAR_MACHINE_LEARNING_JOBS = 'CLEAR_MACHINE_LEARNING_JOBS';

// MEASURE TYPES LIST REDUCER ACTIONS
export const SET_MEASURE_TYPES_LIST = 'SET_MEASURE_TYPES_LIST';
export const CLEAR_MEASURE_TYPES_LIST = 'CLEAR_MEASURE_TYPES_LIST';

// MEASURE UNITS LIST REDUCER ACTIONS
export const SET_MEASURE_UNITS_LIST = 'SET_MEASURE_UNITS_LIST';
export const CLEAR_MEASURE_UNITS_LIST = 'CLEAR_MEASURE_UNITS_LIST';

// METRO AREAS LIST REDUCER ACTIONS
export const SET_METRO_AREAS_LIST = 'SET_METRO_AREAS_LIST';
export const CLEAR_METRO_AREAS_LIST = 'CLEAR_METRO_AREAS_LIST';

// MODAL REDUCER ACTIONS
export const CLEAR_MODALS = 'CLEAR_MODALS';
export const UPDATE_MODAL = 'UPDATE_MODAL';

// MODELS REDUCER ACTIONS
export const CLEAR_MODELS = 'CLEAR_MODELS';
export const SET_MODELS = 'SET_MODELS';

// ADVERTISER MODELS REDUCER ACTIONS
export const CLEAR_ADVERTISER_MODELS = 'CLEAR_ADVERTISER_MODELS';
export const SET_ADVERTISER_MODELS = 'SET_ADVERTISER_MODELS';

// MODEL REDUCER ACTIONS
export const CLEAR_MODEL = 'CLEAR_MODEL';
export const SET_MODEL = 'SET_MODEL';
export const SET_MODEL_ADVERTISERS = 'SET_MODEL_ADVERTISERS';
export const CLEAR_MODEL_ADVERTISERS = 'CLEAR_MODEL_ADVERTISERS';
export const SET_MODEL_OUTPUTS = 'SET_MODEL_OUTPUTS';
export const CLEAR_MODEL_OUTPUTS = 'CLEAR_MODEL_OUTPUTS';

// OVERLAY ACTIONS
export const SHOW_OVERLAY = 'SHOW_OVERLAY';
export const CLEAR_OVERLAY = 'CLEAR_OVERLAY';

// OUTPUTS REDUCER ACTIONS
export const SET_OUTPUTS = 'SET_OUTPUTS';
export const CLEAR_OUTPUTS = 'CLEAR_OUTPUTS';

// OPERATING SYSTEMS REDUCER ACTIONS
export const SET_OPERATING_SYSTEMS_LIST = 'SET_OPERATING_SYSTEMS_LIST';
export const CLEAR_OPERATING_SYSTEMS_LIST = 'CLEAR_OPERATING_SYSTEMS_LIST';

// PARAMETER GROUPS
export const SET_PARAMETER_GROUPS = 'SET_PARAMETER_GROUPS';
export const CLEAR_PARAMETER_GROUPS = 'CLEAR_PARAMETER_GROUPS';

// PARAMETER TYPES
export const SET_PARAMETER_TYPES = 'SET_PARAMETER_TYPES';
export const CLEAR_PARAMETER_TYPES = 'CLEAR_PARAMETER_TYPES';

// PARAMETERS
export const SET_PARAMETERS = 'SET_PARAMETERS';
export const CLEAR_PARAMETERS = 'CLEAR_PARAMETERS';

// PARTNER REDUCER ACTIONS
export const CLEAR_PARTNER = 'CLEAR_PARTNER';
export const SET_PARTNER = 'SET_PARTNER';

// PARTNERS REDUCER ACTIONS
export const CLEAR_PARTNERS = 'CLEAR_PARTNERS';
export const SET_PARTNERS = 'SET_PARTNERS';

// PIXEL TYPE LIST REDUCER ACTIONS
export const CLEAR_PIXEL_TYPES_LIST = 'CLEAR_PIXEL_TYPES_LIST';
export const SET_PIXEL_TYPES_LIST = 'SET_PIXEL_TYPES_LIST';

// PLATFORMS LIST REDUCER ACTIONS
export const SET_PLATFORMS_LIST = 'SET_PLATFORMS_LIST';
export const CLEAR_PLATFORMS_LIST = 'CLEAR_PLATFORMS_LIST';

//  PHYSICAL TAG REDUCER ACTIONS
export const SET_PHYSICAL_TAG = 'SET_PHYSICAL_TAG';
export const CLEAR_PHYSICAL_TAG = 'CLEAR_PHYSICAL_TAG';

//  PHYSICAL TAGS REDUCER ACTIONS
export const SET_PHYSICAL_TAGS = 'SET_PHYSICAL_TAGS';
export const CLEAR_PHYSICAL_TAGS = 'CLEAR_PHYSICAL_TAGS';

//  VIRTUAL TAG REDUCER ACTIONS
export const SET_VIRTUAL_TAG = 'SET_VIRTUAL_TAG';
export const CLEAR_VIRTUAL_TAG = 'CLEAR_VIRTUAL_TAG';

//  VIRTUAL TAGS REDUCER ACTIONS
export const SET_VIRTUAL_TAGS = 'SET_VIRTUAL_TAGS';
export const CLEAR_VIRTUAL_TAGS = 'CLEAR_VIRTUAL_TAGS';

// POSTAL CODES LIST REDUCER ACTIONS
export const SET_POSTAL_CODES_LIST = 'SET_POSTAL_CODES_LIST';
export const CLEAR_POSTAL_CODES_LIST = 'CLEAR_POSTAL_CODES_LIST';

// PORTFOLIO MARGIN REDUCER ACTIONS
export const SET_PORTFOLIO_MARGIN = 'SET_PORTFOLIO_MARGIN';
export const CLEAR_PORTFOLIO_MARGIN = 'CLEAR_PORTFOLIO_MARGIN';

// PORTFOLIO OVERVIEW REDUCER ACTIONS
export const SET_PORTFOLIO_OVERVIEW = 'SET_PORTFOLIO_OVERVIEW';
export const CLEAR_PORTFOLIO_OVERVIEW = 'CLEAR_PORTFOLIO_OVERVIEW';

// REGIONS LIST REDUCER ACTIONS
export const SET_REGIONS_LIST = 'SET_REGIONS_LIST';
export const CLEAR_REGIONS_LIST = 'CLEAR_REGIONS_LIST';

// REPORT PARAMETERS
export const SET_REPORT_PARAMETERS = 'SET_REPORT_PARAMETERS';
export const CLEAR_REPORT_PARAMETERS = 'CLEAR_REPORT_PARAMETERS';

// REPORT TYPE PARAMETER GROUPS
export const SET_REPORT_TYPE_PARAMETER_GROUPS = 'SET_REPORT_TYPE_PARAMETER_GROUPS';
export const CLEAR_REPORT_TYPE_PARAMETER_GROUPS = 'CLEAR_REPORT_TYPE_PARAMETER_GROUPS';

// REPORT TYPE PARAMETERS
export const SET_REPORT_TYPE_PARAMETERS = 'SET_REPORT_TYPE_PARAMETERS';
export const CLEAR_REPORT_TYPE_PARAMETERS = 'CLEAR_REPORT_TYPE_PARAMETERS';

// REPORT TYPES
export const SET_REPORT_TYPES = 'SET_REPORT_TYPES';
export const CLEAR_REPORT_TYPES = 'CLEAR_REPORT_TYPES';

// REPORTS ACTIONS
export const SET_ADVERTISER_REPORTS = 'SET_ADVERTISER_REPORTS';
export const CLEAR_ADVERTISER_REPORTS = 'CLEAR_ADVERTISER_REPORTS';
export const SET_ADVERTISER_REPORT = 'SET_ADVERTISER_REPORT';
export const CLEAR_ADVERTISER_REPORT = 'CLEAR_ADVERTISER_REPORT';

// REPORTS CONFIGURATIONS ACTIONS
export const SET_REPORT_CONFIGURATIONS = 'SET_REPORT_CONFIGURATIONS';
export const CLEAR_REPORT_CONFIGURATIONS = 'CLEAR_REPORT_CONFIGURATIONS';
export const SET_REPORT_CONFIGURATION = 'SET_REPORT_CONFIGURATION';
export const CLEAR_REPORT_CONFIGURATION = 'CLEAR_REPORT_CONFIGURATION';

// REPORTS ACTIONS
export const SET_REVENUE_TYPES_LIST = 'SET_REVENUE_TYPES_LIST';
export const CLEAR_REVENUE_TYPES_LIST = 'CLEAR_REVENUE_TYPES_LIST';

// REPORTS ACTIONS
export const SET_REPORTS = 'SET_REPORTS';
export const CLEAR_REPORTS = 'CLEAR_REPORTS';

// ROLES ACTIONS
export const SET_ROLES = 'SET_ROLES';
export const CLEAR_ROLES = 'CLEAR_ROLES';

// SECURITY AUDITS REDUCER ACTIONS
export const SET_SECURITY_AUDITS = 'SET_SECURITY_AUDITS';

// SEGMENT REDUCER ACTIONS
export const SET_SEGMENT = 'SET_SEGMENT';
export const CLEAR_SEGMENT = 'CLEAR_SEGMENT';

// SEGMENTS REDUCER ACTIONS
export const SET_SEGMENTS = 'SET_SEGMENTS';
export const CLEAR_SEGMENTS = 'CLEAR_SEGMENTS';

// SETTINGS REDUCER ACTIONS
export const CLEAR_SETTINGS = 'CLEAR_SETTINGS';
export const CLEAR_USER_SETTINGS = 'CLEAR_USER_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';

// STATES LIST REDUCER ACTIONS
export const SET_STATES_LIST = 'SET_STATES_LIST';
export const CLEAR_STATES_LIST = 'CLEAR_STATES_LIST';

// STRATEGY TEMPLATE REDUCER ACTIONS
export const SET_STRATEGY_TEMPLATE = 'SET_STRATEGY_TEMPLATE';
export const CLEAR_STRATEGY_TEMPLATE = 'CLEAR_STRATEGY_TEMPLATE';

// STRATEGY TEMPLATES REDUCER ACTIONS
export const SET_STRATEGY_TEMPLATES = 'SET_STRATEGY_TEMPLATES';
export const CLEAR_STRATEGY_TEMPLATES = 'CLEAR_STRATEGY_TEMPLATES';
export const INSERT_STRATEGY_TEMPLATE = 'INSERT_STRATEGY_TEMPLATE';

// STRATEGY REDUCER ACTIONS
export const SET_STRATEGY = 'SET_STRATEGY';
export const CLEAR_STRATEGY = 'CLEAR_STRATEGY';
export const UPDATE_STRATEGY = 'UPDATE_STRATEGY';
export const CLEAR_STRATEGY_WARNINGS = 'CLEAR_STRATEGY_WARNINGS';
export const SET_STRATEGY_WARNINGS = 'SET_STRATEGY_WARNINGS';
export const UPDATE_STRATEGY_WARNINGS = 'UPDATE_STRATEGY_WARNINGS';

// STRATEGY AB TEST REDUCER ACTIONS
export const SET_STRATEGY_AB_TEST = 'SET_STRATEGY_AB_TEST';
export const CLEAR_STRATEGY_AB_TEST = 'CLEAR_STRATEGY_AB_TEST';

// STRATEGY AB TESTS REDUCER ACTIONS
export const SET_STRATEGY_AB_TESTS = 'SET_STRATEGY_AB_TESTS';
export const CLEAR_STRATEGY_AB_TESTS = 'CLEAR_STRATEGY_AB_TESTS';

// STRATEGY BUDGET TYPES LIST REDUCER ACTIONS
export const SET_STRATEGY_BUDGET_TYPES_LIST = 'SET_STRATEGY_BUDGET_TYPES_LIST';
export const CLEAR_STRATEGY_BUDGET_TYPES_LIST = 'CLEAR_STRATEGY_BUDGET_TYPES_LIST';

// STRATEGIES REDUCER ACTIONS
export const SET_STRATEGIES = 'SET_STRATEGIES';
export const CLEAR_STRATEGIES = 'CLEAR_STRATEGIES';
export const INSERT_STRATEGY = 'INSERT_STRATEGY';
export const UPDATE_STRATEGIES = 'UPDATE_STRATEGIES';
export const MERGE_STRATEGY = 'MERGE_STRATEGY';
export const MERGE_CHANGE_ORDER_STRATEGIES = 'MERGE_CHANGE_ORDER_STRATEGIES';

// TAG REDUCER ACTIONS
export const SET_TAG = 'SET_TAG';
export const CLEAR_TAG = 'CLEAR_TAG';

// TAG SUMMARY REDUCER ACTIONS
export const SET_TAG_SUMMARY = 'SET_TAG_SUMMARY';
export const CLEAR_TAG_SUMMARY = 'CLEAR_TAG_SUMMARY';

// TAG SUMMARY REDUCER ACTIONS
export const SET_TAG_FIELDS = 'SET_TAG_FIELDS';
export const CLEAR_TAG_FIELDS = 'CLEAR_TAG_FIELDS';

// TAGS REDUCER ACTIONS
export const SET_TAG_VARIABLE_RULES = 'SET_TAG_VARIABLE_RULES';
export const CLEAR_TAG_VARIABLE_RULES = 'CLEAR_TAG_VARIABLE_RULES';

// TAGS REDUCER ACTIONS
export const SET_TAGS = 'SET_TAGS';
export const CLEAR_TAGS = 'CLEAR_TAGS';

// TAG OPERATORS LIST REDUCER ACTIONS
export const SET_TAG_OPERATORS_LIST = 'SET_TAG_OPERATORS_LIST';
export const CLEAR_TAG_OPERATORS_LIST = 'CLEAR_TAG_OPERATORS_LIST';

// TAG TYPES LIST REDUCER ACTIONS
export const SET_TAG_TYPES_LIST = 'SET_TAG_TYPES_LIST';
export const CLEAR_TAG_TYPES_LIST = 'CLEAR_TAG_TYPES_LIST';

// THIRD PARTY REPORT ACTIONS
export const SET_THIRD_PARTY_REPORT = 'SET_THIRD_PARTY_REPORT';
export const CLEAR_THIRD_PARTY_REPORT = 'CLEAR_THIRD_PARTY_REPORT';

// THIRD PARTY REPORT SOURCES ACTIONS
export const SET_THIRD_PARTY_REPORT_SOURCES_LIST = 'SET_THIRD_PARTY_REPORT_SOURCES_LIST';
export const CLEAR_THIRD_PARTY_REPORT_SOURCES_LIST = 'CLEAR_THIRD_PARTY_REPORT_SOURCES_LIST';

// THIRD PARTY REPORT CADENCES ACTIONS
export const SET_THIRD_PARTY_REPORT_CADENCES_LIST = 'SET_THIRD_PARTY_REPORT_CADENCES_LIST';
export const CLEAR_THIRD_PARTY_REPORT_CADENCES_LIST = 'CLEAR_THIRD_PARTY_REPORT_CADENCES_LIST';

// THIRD PARTY REPORT DELIVERIES ACTIONS
export const SET_THIRD_PARTY_REPORT_DELIVERIES_LIST = 'SET_THIRD_PARTY_REPORT_DELIVERIES_LIST';
export const CLEAR_THIRD_PARTY_REPORT_DELIVERIES_LIST = 'CLEAR_THIRD_PARTY_REPORT_DELIVERIES_LIST';

// THIRD PARTY REPORTS ACTIONS
export const SET_THIRD_PARTY_REPORTS = 'SET_THIRD_PARTY_REPORTS';
export const CLEAR_THIRD_PARTY_REPORTS = 'CLEAR_THIRD_PARTY_REPORTS';

// USER REDUCER ACTIONS
export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';

// VENDOR FEES ACTIONS
export const SET_VENDOR_FEES = 'SET_VENDOR_FEES';
export const CLEAR_VENDOR_FEES = 'CLEAR_VENDOR_FEES';

// VENDORS LIST ACTIONS
export const SET_VENDORS_LIST = 'SET_VENDORS_LIST';
export const CLEAR_VENDORS_LIST = 'CLEAR_VENDORS_LIST';

// VENDORS FEES LIST ACTIONS
export const SET_VENDOR_FEES_LIST = 'SET_VENDOR_FEES_LIST';
export const CLEAR_VENDOR_FEES_LIST = 'CLEAR_VENDOR_FEES_LIST';

// VIDEO BITRATES LIST REDUCER ACTIONS
export const SET_VIDEO_BITRATES_LIST = 'SET_VIDEO_BITRATES_LIST';
export const CLEAR_VIDEO_BITRATES_LIST = 'CLEAR_VIDEO_BITRATES_LIST';

// VIDEO_MIME_TYPES REDUCER ACTIONS
export const SET_VIDEO_MIME_TYPES_LIST = 'SET_VIDEO_MIME_TYPES_LIST';
export const CLEAR_VIDEO_MIME_TYPES_LIST = 'CLEAR_VIDEO_MIME_TYPES_LIST';

// VIDEO PLACEMENTS LIST REDUCER ACTIONS
export const SET_VIDEO_PLACEMENTS_LIST = 'SET_VIDEO_PLACEMENTS_LIST';
export const CLEAR_VIDEO_PLACEMENTS_LIST = 'CLEAR_VIDEO_PLACEMENTS_LIST';

// LOCAL QUERY PARAMETERS REDUCER ACTIONS
export const SET_QUERY = 'SET_QUERY';

// REPORTS LIST REDUCER ACTIONS
export const SET_REPORTS_LIST = 'SET_REPORTS_LIST';
export const CLEAR_REPORTS_LIST = 'CLEAR_REPORTS_LIST';

// REPORT REDUCER ACTIONS
export const SET_REPORT = 'SET_REPORT';
export const CLEAR_REPORT = 'CLEAR_REPORT';

// REPORT FOLDERS REDUCER ACTIONS
export const SET_REPORT_FOLDERS = 'SET_REPORT_FOLDERS';
export const CLEAR_REPORT_FOLDERS = 'CLEAR_REPORT_FOLDERS';

// REPORT FOLDER REDUCER ACTIONS
export const SET_REPORT_FOLDER = 'SET_REPORT_FOLDER';
export const CLEAR_REPORT_FOLDER = 'CLEAR_REPORT_FOLDER';

// REPORT COLUMNS REDUCER ACTIONS
export const SET_REPORT_COLUMNS = 'SET_REPORT_COLUMNS';
export const CLEAR_REPORT_COLUMNS = 'CLEAR_REPORT_COLUMNS';

// REPORT COLUMN REDUCER ACTIONS
export const SET_REPORT_COLUMN = 'SET_REPORT_COLUMN';
export const CLEAR_REPORT_COLUMN = 'CLEAR_REPORT_COLUMN';

// REPORT AUDITS REDUCER ACTIONS
export const SET_REPORT_AUDITS = 'SET_REPORT_AUDITS';
export const CLEAR_REPORT_AUDITS = 'CLEAR_REPORT_AUDITS';

// OPERATORS LIST REDUCER ACTIONS
export const SET_OPERATORS_LIST = 'SET_OPERATORS_LIST';
export const CLEAR_OPERATORS_LIST = 'CLEAR_OPERATORS_LIST';

// AGGREGATE TYPES LIST REDUCER ACTIONS
export const SET_AGGREGATE_TYPES_LIST = 'SET_AGGREGATE_TYPES_LIST';
export const CLEAR_AGGREGATE_TYPES_LIST = 'CLEAR_AGGREGATE_TYPES_LIST';

// DATA TYPES LIST REDUCER ACTIONS
export const SET_DATA_TYPES_LIST = 'SET_DATA_TYPES_LIST';
export const CLEAR_DATA_TYPES_LIST = 'CLEAR_DATA_TYPES_LIST';

// FIELD TYPES LIST REDUCER ACTIONS
export const SET_REPORT_ENTITIES_LIST = 'SET_REPORT_ENTITIES_LIST';
export const CLEAR_REPORT_ENTITIES_LIST = 'CLEAR_REPORT_ENTITIES_LIST';

// FORMAT TYPES LIST REDUCER ACTIONS
export const SET_FORMAT_TYPES_LIST = 'SET_FORMAT_TYPES_LIST';
export const CLEAR_FORMAT_TYPES_LIST = 'CLEAR_FORMAT_TYPES_LIST';

// SORT DIRECTIONS LIST REDUCER ACTIONS
export const SET_SORT_DIRECTIONS_LIST = 'SET_SORT_DIRECTIONS_LIST';
export const CLEAR_SORT_DIRECTIONS_LIST = 'CLEAR_SORT_DIRECTIONS_LIST';

// REPORT ENTITY FIELDS REDUCER ACTIONS
export const SET_REPORT_ENTITY_FIELDS = 'SET_REPORT_ENTITY_FIELDS';
export const CLEAR_REPORT_ENTITY_FIELDS = 'CLEAR_REPORT_ENTITY_FIELDS';

// SLACK CHANNELS REDUCER ACTIONS
export const SET_SLACK_CHANNELS = 'SET_SLACK_CHANNELS';
export const CLEAR_SLACK_CHANNELS = 'CLEAR_SLACK_CHANNELS';

// REPORT SCHEDULES REDUCER ACTIONS
export const SET_REPORT_SCHEDULES = 'SET_REPORT_SCHEDULES';
export const CLEAR_REPORT_SCHEDULES = 'CLEAR_REPORT_SCHEDULES';

// OUTPUT TYPES LIST REDUCER ACTIONS
export const SET_OUTPUT_TYPES_LIST = 'SET_OUTPUT_TYPES_LIST';
export const CLEAR_OUTPUT_TYPES_LIST = 'CLEAR_OUTPUT_TYPES_LIST';

// DASHBOARDS REDUCER ACTIONS
export const SET_DASHBOARDS = 'SET_DASHBOARDS';
export const CLEAR_DASHBOARDS = 'CLEAR_DASHBOARDS';

// DASHBOARD REDUCER ACTIONS
export const SET_DASHBOARD = 'SET_DASHBOARD';
export const CLEAR_DASHBOARD = 'CLEAR_DASHBOARD';

// PANELS REDUCER ACTIONS
export const SET_PANELS = 'SET_PANELS';
export const CLEAR_PANELS = 'CLEAR_PANELS';

// IDENTITY GRAPHS REDUCER ACTIONS
export const SET_IDENTITY_GRAPHS_LIST = 'SET_IDENTITY_GRAPHS_LIST';
export const CLEAR_IDENTITY_GRAPHS_LIST = 'CLEAR_IDENTITY_GRAPHS_LIST';

// IDENTITY GRAPHS TYPES REDUCER ACTIONS
export const SET_IDENTITY_TYPES_LIST = 'SET_IDENTITY_TYPES_LIST';
export const CLEAR_IDENTITY_TYPES_LIST = 'CLEAR_IDENTITY_TYPES_LIST';

// CREATIVE MESSAGES TYPES REDUCER ACTIONS
export const SET_CREATIVE_MESSAGES_LIST = 'SET_CREATIVE_MESSAGES_LIST';
export const CLEAR_CREATIVE_MESSAGES_LIST = 'CLEAR_CREATIVE_MESSAGES_LIST';

// SALESFORCE FLIGHTS REDUCER ACTIONS
export const SET_SALESFORCE_FLIGHTS = 'SET_SALESFORCE_FLIGHTS';
export const CLEAR_SALESFORCE_FLIGHTS = 'CLEAR_SALESFORCE_FLIGHTS';
